import React, { useContext, useLayoutEffect } from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import GoogleMapReact from 'google-map-react';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import FullImage from '../components/full-image';
import Accordion from '../components/accordion';
import Information from '../components/information';
import PortableText from '../components/portable-text';
import { isOpen, convertDay, formatTimes } from '../utility';

export default function Visit({ data }) {
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setGrayFooter(true);
  }, []);

  function mapsLoaded(map, maps) {
    const marker = new maps.Marker({
      position: {
        lat: -37.8442,
        lng: 144.883,
      },
      map: map,
    });
  }

  return (
    <div
      css={css`
        background-color: var(--white);
        padding-bottom: var(--bigMargin);
      `}
    >
      <SEO
        title="Visit"
        imageID={(data.visit.featureImage) ? data.visit.featureImage.asset._id : null}
      />
      <FullImage
        text={`
          Visit/
        `}
        includeTitle
        image={data.visit.featureImage}
      />
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          gap: var(--gutter);
          padding: 0 var(--margin);
          margin-bottom: 15rem;

          > div {
            grid-column: span 4;
            white-space: pre-line;

            @media (max-width: 1080px) {
              grid-column: span 6;
            }

            @media (max-width: 450px) {
              grid-column: span 12;
            }
          }
        `}
      >
        <div>
          <Information
            title="Location"
          >
            <PortableText
              blocks={data.siteSettings._rawAddress}
            />
          </Information>
        </div>
        <div>
          <Information
            title="Hours"
          >
            Currently {(isOpen(data.siteSettings.openHours, data.siteSettings.closedDates)) ? <span css={css`color: var(--primary);`}>open</span> : 'closed'}
            {data.siteSettings.openHours.length > 0 && data.siteSettings.openHours
              .map((time, i) => (
                <div key={i}>
                  {(time.endDay) ? `${convertDay(time.startDay)}–${convertDay(time.endDay)}` : convertDay(time.startDay)} {formatTimes(time.startTime, time.endTime)}
                </div>
              ))
            }
          </Information>
        </div>
        <div>
          <Information
            css={css`
              word-break: break-word;
            `}
            title="Contact"
          >
            <PortableText
              blocks={data.siteSettings._rawContact}
            />
          </Information>
        </div>
      </div>
      <div
        css={css`
          padding: 0 var(--margin);
        `}
      >
        <Accordion
          title="Getting Here"
        >
          <div
            css={css`
              height: 40vw;
              filter: grayscale(100%);
              margin-bottom: var(--margin);
            `}
          >
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyC8oQ1hS7uOE3yclm8LXZg3DlUiq27rido' }}
              defaultCenter={{ lat: -37.8442, lng: 144.883 }}
              defaultZoom={17}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => mapsLoaded(map, maps)}
            >
            </GoogleMapReact>
          </div>
          <PortableText
            blocks={data.visit._rawGettingHere}
          />
        </Accordion>
        {data.visit.information.length > 0 && data.visit.information
          .map((information, i) => (
            <Accordion
              key={i}
              title={information.title}
            >
              <PortableText
                blocks={information._rawContent}
              />
            </Accordion>
          ))
        }
      </div>
    </div>
  );
}

export const query = graphql`
  query {
    visit: sanityVisit(_id: { regex: "/(drafts\\\\.)?visit/" }) {
      featureImage {
        ...Image
        asset {
          description
        }
      }
      _rawGettingHere(resolveReferences: {maxDepth: 10})
      information {
        title
        _rawContent(resolveReferences: {maxDepth: 10})
      }
    }
    siteSettings: sanitySiteSettings(_id: { regex: "/(drafts\\\\.)?siteSettings/" }) {
      _rawAddress(resolveReferences: {maxDepth: 10})
      _rawContact(resolveReferences: {maxDepth: 10})
      openHours {
        startDay
        endDay
        startTime
        endTime
      }
      closedDates {
        startDate
        endDate
      }
    }
  }
`
